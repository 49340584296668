<template>
    <div class="Message xi">
        <nh-com v-if="msgType == 0"></nh-com>
        <h-com v-if="msgType == 1"></h-com>
        <div class="personal-content">
            <div class="personal-box clearfix">
                <div class="personalmesBox">
                       <div class="personalmesBoxT">
                            <img v-if="MessageDetail.imageUrl" :src="$basePath.Authorization + MessageDetail.imageUrl" alt="">
                            <img v-else src="../assets/pingMsg.png" alt="">
                            <div class="personalmesBoxTR">
                                <span>{{ MessageDetail.title}}</span>
                                <div class="contentBoxRLCP">{{ MessageDetail.createTime | timefilters }}</div>
                            </div>
                       </div>
                       <div class="personalmesBoxB">
                            <span>详情</span>
                            <div v-html="MessageDetail.content"></div>
                       </div>
                </div>

            </div>
        </div>
        <ft-com></ft-com>
    </div>
</template>

<script>
import { MessageDomain } from '../components/personal/Message';
import ftCom from '../components/footer'
import nhCom from "../components/navheader";
import hCom from "../components/header";
import hdImg from "../components/headerImg";
export default {
    name: "Message",
    components: {
        ftCom,
        nhCom,
        hdImg,
        hCom
    },
    data() {
        var MessageDomainTemp = new MessageDomain(this.TokenClient, this.Services.Authorization);
        return {
            labelPosition: 'right',
            MessageDetail: {},
             msgType: this.$route.query.typeCom ? this.$route.query.typeCom : 0,
            Result: MessageDomainTemp
        };
    },
    mounted() {
        this.goMessageDetail(this.$route.query.id);
    },
    methods: {
        goMessageDetail(id) {
            var _this = this
            _this.Result.GetpingMessageDetail(id, function (data) {
                _this.MessageDetail = data.data;
            }, function (error) {
                console.log(error);
            })
        },
        handleImagePreview(file) {
            this.Image.dialogImageUrl = file.filePath;
            this.Image.dialogVisible = true;
        }
    }
}
</script>

<style scoped>
@import "../assets/css/footer.css";

#page {
    text-align: center;
    padding: 100px 0 30px;
}

.contentBoxR {
    background: #F5F7FA;
    padding: 15px 30px;
    margin: 30px;
    margin-bottom: 0;
    display: flex;
    align-items: center;
}

.contentNone {
    width: 100%;
    text-align: center;
    margin: 20px 0;
}

.contentBoxRC {
    background: #ffffff;
    padding: 0 30px;
    margin-top: 10px;
    display: flex;
    /* align-items: center; */
}
.contentBoxRCP {
    background: #ffffff;
    padding: 0 30px;
    margin-top: 10px;
    display: flex;
    align-items: center;
}

.mesTopItemRead {
    background: #ffffff;
    padding: 0 30px;
    margin-top: 10px;
    display: flex;
    color: #999999;
}

.mesTopItemRead .collapseCon {
    color: #999999;
}

.contentBoxRLC {
    width: 30%;
    height: 47px;
    line-height: 47px;
    padding-left: 30px;
}
.contentBoxRLCP {
    width: 30%;
    height: 47px;
    line-height: 47px;
}

.contentBoxRRC {
    width: 70%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.contentBoxRRC ::v-deep .el-collapse {
    width: 100% !important;
    border: none !important;
}

.contentBoxRRC ::v-deep .el-collapse .el-collapse-item__wrap {
    border: none !important;
}

.contentBoxRC .contentBoxRRC ::v-deep .el-collapse .el-collapse-item__header {
    border: none !important;
}

.mesTopItemRead .contentBoxRRC ::v-deep .el-collapse .el-collapse-item__header {
    border: none !important;
    color: #999999 !important;
}

.goHandl {
    color: blue;
    text-decoration: underline;
    cursor: pointer;
}

.contentBoxRL {
    width: 30%;
}

.contentBoxRR {
    width: 70%;
}

.banner {
    width: 100%;
    height: 160px;
    background: url("../assets/img/news_banner.jpg") center no-repeat;
}

.banner-wrap {
    position: relative;
    width: 1200px;
    height: 100%;
    margin: 0 auto;
}

.banner-text {
    float: left;
}

.banner-text h3 {
    padding-top: 35px;
    font-size: 26px;
    font-weight: normal;
    color: #ffffff;
}

.banner-text p {
    font-size: 14px;
    color: #ffffff;
    margin-top: 20px;
}

.banner-text p a {
    color: #ffffff;
    font-size: 14px;
    margin: 0 5px;
}

/**/
.personal-content {
    background: #f7f7f7;
    padding: 40px 0;
}

.personalmesBox {
    min-height: 740px;
    background: #FFFFFF;
    padding: 30px 40px;
    width: 100%;
}

.personal-box {
    width: 1200px;
    margin: 0 auto;
}

.personal-left {
    width: 20%;
    min-height: 740px;
    background: #FFFFFF;
    border-radius: 10px;
}

.personal-right {
    width: 75%;
    min-height: 740px;
    background: #FFFFFF;
    box-sizing: border-box;
    border-radius: 10px;
}

.personal-right-tit {
    border-bottom: 1px solid #e5e5e5;
    padding: 20px;
}

.personal-right-tit .iconfont {
    color: #04a290;
    font-size: 24px;
    margin-right: 5px;
}

.personal-right-tit span {
    display: inline-block;
    font-size: 24px;
    font-weight: 700;
    color: #333333;
}

.personal-right-tit span em {
    font-style: normal;
    color: #04a290;
}

.personal-right-tit .el-icon-delete {
    float: right;
    font-size: 18px;
}

.msg-list {
    padding: 0 20px;
}

.msg-list .msg-item {
    padding: 8px 0;
    border-bottom: 1px dashed #e5e5e5;
    line-height: 40px;
    height: 40px;
}

.msg-list .msg-item h4 {
    color: #999999;
    font-weight: normal;
    width: 700px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    cursor: pointer;
}

.msg-list .msg-item.noread h4 {
    color: #333333;
}

.msg-list .msg-item p {
    color: #999999;
}

.msg-detail {
    padding: 20px;
    display: none;
}

.msg-detail .mess-tit {
    text-align: center;
}

.msg-detail .mess-info p {
    text-indent: 28px;
}

.mess-info .item-img {
    margin-right: 10px;
    display: flex;
    justify-content: flex-start;
    justify-items: center;
    flex-wrap: wrap;
}
.item-imgP {
    /* margin-right: 10px; */
    display: flex;
    justify-content: flex-start;
    justify-items: center;
    flex-wrap: wrap;
    width: 20%;
     padding-right: 15px;
}
.item-imgP img {
     width: 100%;
    
    /* margin: 0 1px; */
}
.item-imgP div {
    width: 100%;
    /* margin: 0 1px; */
}

.item-img img {
    width: 118px;
    margin: 0 1px;
    /* height: 118px; */
}

.contentBoxTitle {
    font-weight: bold;
    font-size: 20px;
    color: #333333;
    padding: 30px;
    padding-bottom: 0;
    cursor: pointer;
}

.contentBoxTitleR {
    font-size: 16px;
    color: #333333;
    padding: 30px;
    padding-bottom: 0;
    cursor: pointer;
}

.mesTopItem1 {
    min-height: 25px;
    min-width: 25px;
    border-radius: 50%;
    color: #FFFFFF;
    background-color: #EB1E1E;
    position: absolute;
    top: 5px;
    right: 5px;
}

.contentBoxContent {
    width: 150px;
    padding: 10px;
    text-align: center;
    background: #333333;
    border-radius: 6px;
    color: #FFFFFF;
    margin-left: 30px;
    margin-top: 16px;
    cursor: pointer;
}

.contentBoxContentno {
    width: 150px;
    padding: 10px;
    border: 1px solid #999999;
    text-align: center;
    background: #ffffff;
    border-radius: 6px;
    color: #999999;
    margin-left: 30px;
    margin-top: 16px;
    cursor: pointer;
}
.personalmesBoxT {
    display: flex;
    /* align-items: center; */
    justify-content: space-between;
    border-bottom: 1px solid #EBEEF5;
    padding-bottom: 40px;

}
.personalmesBoxT img {
    width: 40%;
    height: 240px;
}
.personalmesBoxTR {
    flex: 1;
    width: 100%;
    margin-left: 50px;
}
.personalmesBoxTR span {
    font-size: 24px;
    color: #333333;
}
.personalmesBoxTR div {
   font-size: 18px;
   color: #666666;
   margin-top: 5px;
}
.personalmesBoxB {
    margin-top: 15px;
}
.personalmesBoxB span {
    font-size: 24px;
    color: #333333;
}
</style>
